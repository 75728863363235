<template>
  <div>
    <v-btn type="submit" color="#1877F2" dark block @click="FBLogin" depressed x-large class="mt-5" :disabled="loading" :loading="loading">
      <v-icon dark large class="mr-2">mdi-facebook</v-icon>
      <template v-slot:loader>
        <span>Loading...</span>
      </template>
      Login with Facebook
    </v-btn>
    <div class="relative">
      <v-divider class="my-10"></v-divider>
      <div class="or">
        <v-btn elevation="0" fab x-small tabindex="-1">or</v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { FBLogin } from '../utils/fbApi'

export default {
  data () {
    return {
      loading: false
    }
  },
  methods: {
    FBLogin: FBLogin
  }
}
</script>
<style lang="scss" scoped>
.or {
  position: absolute;
  bottom: -15px;
  display: flex;
  justify-content: center;
  width: 100%;
  .or-button {
    z-index: 0;
    tab-index: 1;
  }
}
</style>
