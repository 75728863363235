<template>
  <v-app-bar :color="'transparent'" fixed flat app v-if="!onboarding">
    <v-toolbar-title><router-link :to="{name: 'home'}"><v-img :src="$vuetify.theme.dark ? require('@/assets/images/logoWhiteFull.png') : require('@/assets/images/logoBlueFull.png')" width="130"></v-img></router-link></v-toolbar-title>
    <v-spacer></v-spacer>
    <theme />
    <v-btn to="login" depressed color="transparent" class="mr-3">
      Login
    </v-btn>
    <v-btn to="register" outlined>
      Get Started
    </v-btn>
  </v-app-bar>
</template>

<script>
import Theme from '../components/Theme'
export default {
  props: {
    onboarding: {
      type: Boolean,
      default: false
    }
  },
  components: { Theme }
}
</script>

<style scoped>

</style>
