<template>
  <v-container class="fill-height">
    <public-header-access />
    <v-row justify="center" align="center">
      <v-col :md="7" :lg="6" :xl="4" :sm="8" :cols="12">
        <div class="d-flex flex-column align-center">
          <v-img :src="$vuetify.theme.dark ? require('@/assets/images/logoWhite.png') : require('@/assets/images/logoBlue.png')" width="45"/>
        </div>
        <transition name="fade" appear>
          <login-form />
        </transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginForm from '@/components/LoginForm/'
import PublicHeaderAccess from '../../components/PublicHeaderAccess'
export default {
  mounted () {
    this.$store.dispatch('unsetUser')
  },
  metaInfo () {
    return {
      title: 'Login to your account | Gradbee',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'Login to connect to a community of student designers, developers, artists, researchers and employers'
      }]
    }
  },
  components: {
    LoginForm,
    PublicHeaderAccess
  }
}
</script>

<style scoped>

</style>
