<template>
  <v-card :loading="loading" class="pa-4 pa-sm-8" color="transparent" elevation="0">
    <h2 class="access-header">Welcome Back!</h2>
    <social-auth v-if="fbStatus === 'connected' && false" />
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate()" id="login-form" class="v-text-field--rounded form">
      <v-text-field
        ref="username"
        :loading="loading"
        v-model="username"
        :rules="usernameRules"
        label="Username"
        outlined
        required></v-text-field>
      <v-text-field
        autocomplete="new-password"
        v-model="password"
        :loading="loading"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="passwordRules"
        :type="showPassword ? 'text' : 'password'"
        label="Password"
        outlined
        @click:append="showPassword = !showPassword"
        required></v-text-field>
      <v-btn
        type="submit"
        color="secondary"
        block
        depressed
        x-large
        class="mr-4"
        :disabled="loading"
        :loading="loading">
        <template v-slot:loader>
          <span>Loading...</span>
        </template>
        Login
      </v-btn>
      <div class="mt-5 text-center">
        New User? <router-link :to="{name: 'register'}">Register</router-link>
      </div>
      <v-alert text outlined color="error" icon="mdi-fire" v-if="error" class="mt-5">
        {{ error }}
      </v-alert>
    </v-form>
  </v-card>
</template>

<script>
import { onLogin } from '@/vue-apollo.js'
import SocialAuth from '../components/SocialAuth.vue'
const LOGIN = require('../graphql/login.graphql')
export default {
  data: () => ({
    valid: true,
    loading: false,
    username: '',
    usernameRules: [
      v => !!v || 'E-mail is required'
    ],
    password: '',
    passwordRules: [
      v => !!v || 'Password is required'
    ],
    showPassword: false,
    error: null
  }),
  computed: {
    fbStatus () {
      return this.$store.state.fbStatus
    }
  },
  methods: {
    validate () {
      if (this.$refs.form.validate()) {
        this.login()
      }
    },
    async login () {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: LOGIN,
          variables: {
            username: this.username,
            password: this.password
          },
          update: (cache, { data: { login } }) => {
            onLogin(this.$apollo.provider.defaultClient, login.token, this.username)
            this.$router.push({ name: 'feed' })
          }
        })
      } catch (e) {
        this.loading = false
        console.log(e)
        this.error = e
      }
    }
  },
  watch: {
    username () {
      this.error = null
    },
    password () {
      this.error = null
    }
  },
  components: {
    SocialAuth
  }
}
</script>
<style lang="scss" scoped>
  .access-header {
    text-align: center;
    padding: 1.5rem 0;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.75rem;
  }
  @media (max-width: 960px) {
    .access-header {
      font-size: 1.25rem;
    }
  }
</style>
